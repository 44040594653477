//Migrated
<template lang="pug">
.layout-wrapper
  slot
  slot(name="header")

  .container-fluid.top-section.layout-container(
    v-if="shouldHaveSolresor"
    :class="containerClass"
  )
    slot(name="solresorContent")

  .position-relative(:class="containerClass")
    .container-content.container-fluid.banner-layout.position-static(
      v-if="shouldNotBeEmpty",
      :class="`banner-layout-${getBannerType}`"
    )
      slot(name="banner")
      .layout-container.mx-auto.h-100(v-if="shouldHaveContainer")
        slot(name="contentNoWrapper")
        slot(
          v-if="!isCharter"
          name="sidebar"
          )
        .col-12.px-0.position-static.float-left.float-print-none(
          v-if="shouldHaveWrapper"
          :class="{ 'col-lg-9': !isCharter }"
        )
          slot(name="hero")
          slot(name="content")

  slot(name="footer")
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    containerClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState(useRootStore, {
      bannerType: 'pageBannerType',
    }),

    ...mapState(useLocaleStore, ['isCharter']),

    getBannerType () {
      return this.bannerType ?? 'default'
    },

    shouldHaveWrapper () {
      return !!this.$slots?.hero || !!this.$slots?.content
    },

    shouldHaveContainer () {
      return !!this.$slots?.contentNoWrapper || !!this.$slots?.sidebar || this.shouldHaveWrapper
    },

    shouldNotBeEmpty () {
      return !!this.$slots?.banner || this.shouldHaveContainer
    },

    shouldHaveSolresor () {
      return !!this.$slots?.solresorContent
    },
  }
})
</script>

<style lang="scss">
.layout-head {
  @media (max-width: $lg) {
    height: 550px;
  }

  @media (max-width: $md) {
    min-height: 470px;
    max-height: 85vh;
  }

  @media print {
    height: 450px;
  }
}

.layout-container {
  @media (min-width: $xl) {
    width: 85% !important;
  }
}

/*! purgecss start ignore */
.identity--rolfs {
  .banner-layout {
    &.container-content {
      max-width: $container-width;
    }
  }
}

.identity--solresor {
  .banner-layout-large {
    .layout-container {
      max-width: 1325px;
    }
  }
}

.banner-layout {
  &:not(.banner-layout-map) {
    #side-menu {
      @media (min-width: $lg) {
        margin-top: $top-section-margin-top;
      }
    }
  }

  &.banner-layout-small {
    .layout-head {
      height: 420px;
    }
  }

  &.banner-layout-default {
    .layout-head {
      @media (min-width: $md) {
        height: 630px;
      }
    }
  }

  &.banner-layout-large {
    .layout-head {
      @media (min-width: $md) {
        height: 700px;
      }
    }
  }
}

.banner-text-container {
  padding: 0 120px 0 120px;

  .display-3 {
    letter-spacing: 1px;
  }

  .banner-text-title {
    line-height: 1.1em;
  }

  @media (min-width: $sm) {
    max-height: 300px;
    padding: 0;
  }

  @media (max-width: $md) {
    max-height: 300px;

    .banner-text-title {
      font-size: 3.4rem;
    }
    .banner-text-subtitle {
      font-size: 1.7rem;
    }
  }

  @media (max-width: $xxl) {
    padding: 0 .625em 0 .625em;
  }
}
/*! purgecss end ignore */
</style>
